/******************************************************************************
 * Atoms / Button / Circle / Base
 *****************************************************************************/

.ap-c-button-circle {
  --ap-c-button-circle--BackgroundColor: var(--ap-global--palette-white);
  --ap-c-button-circle--Color: var(--ap-global--palette-black);
  --ap-c-button-circle--FontSize: .5rem; /* 8px */
  --ap-c-button-circle--Padding: 1.5rem;  /* 24px */

  --ap-c-button--sm--FontSize: var(--ap-global--FontSize-label--s);
  --ap-c-button--sm--Padding: 2.5rem;  /* 40px */


  /**
   * Icon
   */

  --ap-c-button-circle__icon--InlineSize: .55rem;
  --ap-c-button-circle__icon--sm--InlineSize: calc(var(--step--2) * 1.12);
}


/**
 * Component
 */

.ap-c-button-circle {
  background-color: var(--ap-c-button-circle--BackgroundColor);
  border-radius: 50%;
  border: var(--ap-global--Border--black);
  color: var(--ap-c-button-circle--Color);
  box-sizing: border-box;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  font-size: var(--ap-c-button-circle--FontSize);
  justify-self: start;
  padding: calc(var(--space-l) * 1.05);
  transition: border .1s linear;
  cursor: inherit;
}

.ap-c-button-circle.ap-m-negative {
  background-color: var(--ap-c-button-circle--Color);
  color: var(--ap-c-button-circle--BackgroundColor);
  border: var(--ap-global--Border--white);
  outline: 1px solid transparent;
  transition: outline-color .2s linear;
}
.ap-c-button-circle.ap-m-negative:hover {
  outline: 1px solid var(--ap-global--palette-white);
  transition: none;
}

.ap-c-button-circle.ap-m-rotate-45 {
  transform: rotateZ(45deg);
}

.ap-c-button-circle.ap-m-rotate-90 {
  transform: rotateZ(90deg);
}


/**
 * Icon
 */

.ap-c-button-circle > svg {
  inline-size: var(--ap-c-button-circle__icon--InlineSize);
  block-size: auto;
}


@supports not (block-size: 0) {
  .ap-c-button-circle > svg {
    height: auto;
    width: var(--ap-c-button-circle__icon--InlineSize);
  }
}

/** Small */
@media all and (min-width: 67.5rem) {
  .ap-c-button-circle {
    --ap-c-button-circle--FontSize: var(--ap-c-button--sm--FontSize);
    --ap-c-button-circle--Padding: var(--ap-c-button--sm--Padding);
  }

  .ap-c-button-circle > svg {
    inline-size: var(--ap-c-button-circle__icon--sm--InlineSize);
  }

  @supports not (block-size: 0) {
    .ap-c-button-circle > svg {
      width: var(--ap-c-button-circle__icon--sm--InlineSize);
    }
  }
}
