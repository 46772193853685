/******************************************************************************
 * Organisms / Header
 *****************************************************************************/

.ap-c-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-block-start: var(--space-2xl); // 63px
  padding-inline: var(--ap-global--page--paddingsize); // 41px
  box-sizing: border-box;
  color: var(--ap-c-page--BackgroundColor);
  // height: 10rem; // 160px

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    max-width: var(--ap-global--breakpoints--block-xl);
    margin: 0 auto;
    width: 100%;
    height: 100%;

    > a {
      display: flex;
      align-items: flex-end;
      gap: clamp(0.625rem, -0.1786rem + 1.1905vi, 1.25rem);
      justify-content: flex-end;

      svg {
        --fluid-14-24: clamp(0.875rem, 0.0714rem + 1.1905vi, 1.5rem);
        width: var(--fluid-14-24);
        height: clamp(1.25rem, 0.4464rem + 1.1905vi, 1.875rem);
        transform: scaleX(-1);
      }
    }
  }

  &.home {
    height: 100vh;
    position: relative;  // contain background

    > img {
      height: 100%;
      inset: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      width: 100%;
      z-index: 0;
    }

    &::before {
      background-attachment: fixed;
      background-image: linear-gradient(42deg, transparent 54%, var(--ap-global--palette-black--36) 85%, rgba(32, 32, 32, 0.52));
      background-size: cover;
      content: "";
      display: block;
      inset: 0;
      position: absolute;
      z-index: 1;

      @media all and (min-width: 67.5rem) {
        background-image: linear-gradient(
        to bottom,
        transparent 50%,
        var(--ap-global--palette-black--36) 72%,
        var(--ap-global--palette-black)
      );
      }
    }

    .container {
      max-width: unset;
      z-index: 2;

      > a svg {
        display: none;
      }
    }
  }

  .little-description {
    font: var(--ap-global--Font-paragraph--sm-regular);
    line-height: 1.625rem;
    margin-bottom: 7.313rem; // 117px
    display: none;

    @media all and (min-width: 67.5rem) {
      display: block;
    }
  }

  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--ap-global--palette-black);
    opacity: 0;
  }
}
