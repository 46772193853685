/******************************************************************************
 * Atoms / Cursor
 *****************************************************************************/

.cursor-hover-item {
  cursor: initial;

  @media all and (min-width: 67.5rem) {
    cursor: none;
  }
}

.cursor {
  display: none;
  translate: -50% -50%;
  position: fixed;
  left: 0;
  top: 0;

  .cursor--small,
	.cursor--large,
	.cursor--text {
    opacity: 0;
		border-radius: 50%;
		width: var(--cursor-size);
		height: var(--cursor-size);
		// mix-blend-mode: difference;
		pointer-events: none;
		user-select: none;

    // @-moz-document url-prefix() {
    //   translate: -50% -50%;
    // }

    // // for safari (may be useless)
    // @media not all and (min-resolution: 0.001dpcm) {
    //   translate: -50% -50%;
    // }
	}

  .ap-c-button-circle {
    --ap-c-button-circle--BackgroundColor: transparent;
  }

  @media all and (min-width: 67.5rem) {
    display: block;
  }
}

svg#text-cursor {
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  width: 150px;
  animation: rotation 6.5s infinite linear;
  pointer-events: none;
  transition: top .07s, left .07s;
  transition-timing-function: ease-out;
  display: none;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  path {
    opacity: var(--show, 0);
    transition: opacity 0.2s;
  }

  #text {
    font-weight: 300;
    text-transform: uppercase;
  }

  @media all and (min-width: 67.5rem) {
    display: block;
  }
}
