/** ***************************************************************************
 * Molecules / Forms / Field
 *************************************************************************** */

.lm-c-field {

}



/**
 * Component
 * 1: contain label
 */

.lm-c-field {
  position: relative;  /* 1 */
}



/**
 * Label
 * 1: put label behind widget
 * 2: extend label to the whole row
 */

.lm-c-field > label {
}

.lm-c-field > label[for] {
  cursor: pointer;
}


.lm-c-field.lm-m-disabled > label {
  cursor: inherit;
}

.lm-c-field > label::after {
  content: "";  /* 2 */
  display: block;  /* 2 */
  inset: 0;  /* 2 */
  position: absolute;  /* 2 */
  z-index: 0;  /* 1 */
}

@supports not (inset: 0) {
  .lm-c-field > label::after {
    bottom: 0;  /* 2 */
    left: 0;  /* 2 */
    right: 0;  /* 2 */
    top: 0;  /* 2 */
  }
}



/**
 * Form widget
 * 1: put widget in front of label
 */

.lm-c-field > label + * {
  z-index: 1;  /* 1 */
}
