/******************************************************************************
 * Molecules / Call to action
 *****************************************************************************/

.ap-c-button.ap-c-button-cta {
  display: inline-flex;
  padding: 1em 1.88em;
  align-items: center;
  cursor: pointer;

  .ap-c-section_title {
    line-height: .91em;
    margin-right: 2.438rem;
}

  svg {
    width: 1rem;
  }

  &:hover {
    .ap-c-section_title {
      font-weight: 300 !important;

      .ap-u-italic {
        font-variation-settings: "ital" 0;
        // font-style: normal;
      }
    }
  }
}
