/******************************************************************************
 * Organisms / Intro
 *****************************************************************************/

.ap-c-intro {
  position: relative;
  display: grid;
  grid-template-columns: var(--ap-c-page--Grid-Template);
  grid-gap: 0 var(--ap-c-page--Grid-Gap);
  justify-content: center;
  align-items: end;
  background-color: var(--ap-global--palette-black);
  color: var(--ap-global--palette-white);
  padding-inline: var(--ap-global--page--paddingsize);
  padding-block: 3.125rem 8rem;

  .main-text {
    font-size: 1.375rem;
    grid-column: 1 / span 2;
    order: 1;
    color: white;

    @media (min-width: 67.5rem) {
      font: var(--ap-global--Font-paragraph--lg-regular);
      line-height: var(--step-5);
      grid-column: 6 / span 7;
      order: 0;
    }
  }

  .secondary-text {
    font: var(--ap-global--Font-paragraph--sm-regular);
    grid-column: 1 / span 2;
    order: 2;

    @media (min-width: 67.5rem) {
      order: 0;
      grid-column: 1 / span 2;
    }
  }

  @media (min-width: 67.5rem) {
    padding-block: 7.813rem calc(var(--space-3xl) * 4.667);

    p {
      margin-block-end: 0;
    }
  }

}
