/******************************************************************************
 * Organisms / Section
 *****************************************************************************/

.ap-c-section {
  --common-gap: clamp(3rem, 0.2679rem + 4.0476vi, 5.125rem);
  // max-width: var(--ap-global--breakpoints--block-xl);
  // margin: 0 auto;
  padding-block: var(--space-m) clamp(4.125rem, 0.3482rem + 5.5952vi, 7.0625rem);
  --ap-c-section__content-color: var(--ap-global--palette-gray-dark);

  .main-container {
    display: grid;
    grid-template-columns: var(--ap-c-page--Grid-Template);
    grid-gap: 0 var(--ap-c-page--Grid-Gap);
    padding-inline: var(--ap-global--page--paddingsize);

    > * {
      grid-column: 1 / span 2;
    }

    .section-image {
      height: 22.125rem;
      width: 100%;
      object-fit: cover;
      max-width: 100%;
      margin-block-end: 5.75rem;
    }

    .cta-find-out,
    .section-etymology {
      font: var(--ap-global--Font-book--s-regular);
      font-size: .875rem;
    }
    .cta-find-out {
      margin-block-start: 0;
      color: var(--ap-c-section__content-color);
    }
    .section-etymology {
      margin-block-start: 1.563rem;
      color: var(--ap-c-section__content-color);
    }

    .ap-c-section_title {
      font-size: 3rem;
    }

    .section-description,
    .services-title {
      font: var(--ap-global--Font-label--md-medium);
      font-size: 1.125rem;
    }
    .section-description {
      margin-block-start: .438rem;
      color: var(--ap-c-section__content-color);
    }

    .ap-c-button-circle {
      cursor: pointer;
      margin-block: 2.5rem;
    }

    .services-title {
      margin-block-end: .875rem;
      color: var(--ap-c-section__content-color);
    }

    .section-services {
      font: var(--ap-global--Font-book--sm-regular);
      font-size: 1rem;
      line-height: 1.5rem;
      margin-block-end: 2.5rem;
      color: var(--ap-c-section__content-color);
    }

    .splide {
      margin-block-end: var(--common-gap);
    }

    .ap-u-button.ap-c-button-cta {
      .ap-c-section_title {
        font-size: 2.33rem;
        margin-right: 1.9rem;
      }
      svg {
        width: 0.75rem;
      }
    }
    .cta-section-footer {
      display: grid;
      grid-template-columns: var(--ap-c-page--Grid-Template);
      grid-gap: var(--common-gap) var(--ap-c-page--Grid-Gap);

      > * {
        grid-column: 1 / span 2;
      }

      .section-other-image {
        max-width: 100%;
        order: var(--image-order);
      }

      .ap-c-button-cta {
        order: 1;
      }
    }
  }
  .ap-c-video {
    margin-block-start: var(--common-gap);
  }

  .main-container.large {
    display: none;
  }

  &.instrument {
    .main-container .cta-section-footer {

      .images-slider {
        display: flex;
        gap: 15px;
        overflow-x: auto;
        scrollbar-width: none;
        margin-inline-end: calc(-1* var(--ap-global--page--paddingsize));

        .section-other-image {
          --fluid-483-106667: clamp(30.1875rem, 21.3333rem + 37.778vi, 66.6669rem);
          max-width: 85%;
          max-height: var(--fluid-483-106667);
          object-fit: cover;

          &:nth-child(2) {
            padding-inline-end: var(--ap-global--page--paddingsize);

            @media all and (min-width: 67.5rem) {
              padding-inline-end: unset;
            }
          }

          @media all and (min-width: 67.5rem) {
            max-width: 100%;
          }
        }
      }

      .ap-c-button-cta {
        order: 10;
      }
    }
  }

  @media all and (min-width: 67.5rem) {
    .main-container.mobile {
      display: none;
    }

    .main-container.large {
      display: grid;

      .ap-c-cta_large {
        margin-block-end: calc(var(--space-2xl) * 1.34);

        .ap-c-section_title {
          font: var(--ap-global--Font-title--2xl-regular);
        }
      }

      .section-content {
        .ap-c-section_title {
          font: var(--ap-global--Font-title--md-regular);
        }
        .section-etymology {
          font: var(--ap-global--Font-book--s-regular);
          margin-block-start: .75rem;
        }
        .section-description {
          font: var(--ap-global--Font-paragraph--md-medium);
          margin-block-start: .813rem;
        }
      }

      .section-image,
      .section-services {
        margin-block-end: 0;
      }
      .section-image {
        height: calc(var(--space-xs-s) * 42.33);
      }
      .section-services {
        font: var(--ap-global--Font-book--sm-regular);
      }
      .services-title {
        font: var(--ap-global--Font-label--md-medium);
      }

      .ap-u-button.ap-c-button-cta {
        .ap-c-section_title {
          font-size: var(--step-4);
        }
        svg {
          width: var(--step-0);
        }
      }
      .ap-c-button-circle {
        margin-block: 0;
      }
    }

    &.impetus {
      .ap-c-cta_large {
        grid-column: 3 / span 8;
        justify-content: flex-end;
      }

      .section-image {
        grid-column: span 6;
      }
      .filler {
        grid-column: 7;
        border-right: 1px solid var(--ap-global--palette-black);
      }
      .section-content {
        grid-column: 8 / span 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .ap-c-button.ap-c-button-cta {
        grid-column: 8 / span 4;
        margin-block: clamp(4.125rem, 0.3482rem + 5.5952vi, 7.0625rem) clamp(3rem, 0.2679rem + 4.0476vi, 5.125rem);
      }

      .splide {
        grid-column: 2 / span 11;
      }
    }

    &.experience {
      .ap-c-cta_large {
        grid-column: 3 / span 7;
        justify-content: flex-start;
        flex-wrap: wrap;
        order: -1;

        > :first-child {
          width: 100%;
          margin-bottom: 0.75rem;
          margin-left: 0.438rem;
        }

        .ap-c-section_title {
          margin-left: 0;
        }
      }

      .section-image {
        order: 3;
        grid-column: 7 / span 6;
      }
      .filler {
        order: 2;
        grid-column: 4;
        border-left: 1px solid var(--ap-global--palette-black);
      }
      .section-content {
        order: 1;
        grid-column: 1 / span 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .ap-c-button.ap-c-button-cta {
        order: 11;
        grid-column: 9 / span 4;
        margin-block: clamp(11.375rem, 0.9286rem + 15.4762vi, 19.5rem) var(--common-gap);
        justify-self: end;
      }

      .section-other-image {
        order: calc(var(--image-order) + 10);
        object-fit: cover;
        width: 100%;
        // $startPosition: calc(5*(var(--image-order) - 1));
        // grid-column: calc(3 + $startPosition) / span 5;
        // margin-block-start: 8.313rem;

        &:nth-of-type(2) {
          --fluid-39225-697: clamp(24.5156rem, 0.0268rem + 36.2798vi, 43.5625rem);
          grid-column: 1 / span 7;
          grid-row: 3 / 5;
          margin-block-start: clamp(8.3125rem, 0.6786rem + 11.3095vi, 14.25rem);
          height: var(--fluid-39225-697);
        }
        &:nth-of-type(3) {
          --fluid-2775-49333: clamp(17.3438rem, 0.0003rem + 25.694vi, 30.8331rem);
          grid-column: 8 / span 5;
          height: var(--fluid-2775-49333);
          // grid-row: 3 / 5;
        }
      }
    }

    &.instrument {
      .ap-c-cta_large {
        grid-column: 4 / span 9;
        justify-content: flex-start;
      }

      .section-image {
        grid-column: span 6;
      }
      .filler {
        grid-column: 9;
        border-right: 1px solid var(--ap-global--palette-black);
      }
      .section-content {
        grid-column: 10 / span 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .section-other-image {
        $startPosition: calc(5*(var(--image-order) - 1));
        grid-column: calc(3 + $startPosition) / span 5;
        margin-block-start: var(--space-4xl);
        min-height: calc(var(--space-3xl) * 6.43);
        object-fit: cover;
      }

      .ap-c-button.ap-c-button-cta {
        grid-column: 3 / span 4;
        margin-block-start: clamp(4.5625rem, 0.3839rem + 6.1905vi, 7.8125rem);
      }
    }

    .ap-c-video {
      // grid-template-columns: var(--ap-c-page--Grid-Template);
      // grid-gap: var(--ap-c-page--Grid-Gap);
      margin-inline: var(--ap-global--page--paddingsize);
    }
  }
}

.default-cursor {
  cursor: initial;
}
