/******************************************************************************
 * Molecules / Cta Large
 *****************************************************************************/

.ap-c-cta_large {
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  cursor: pointer;
  position: relative;  // contain link

  p {
    font: var(--ap-global--Font-paragraph--sm-regular);
  }

  .ap-c-section_title {
    font: var(--ap-global--Font-title--xl-regular);
    margin-right: var(--space-2xl);  // 5.125rem;
    margin-left: 2.625rem;
  }

  .ap-c-button-circle::before {
    content: "";
    display: block;
    inset: 0;
    position: absolute;
  }

  &:hover {
    .ap-c-section_title {
      font-weight: 300 !important;

      .ap-u-italic {
        font-variation-settings: "ital" 0;
        // font-style: normal;
      }
    }
    .ap-c-button-circle {
      box-shadow: inset 0 0 0 1px currentColor;
    }
  }
}
