/** ***********************************************************************************
 * Strong text styles
 *********************************************************************************** */

body {
  --ap-c-page__strong--FontWeight: var(--ap-global--FontWeight-medium);
}



b,
strong {
  font-weight: var(--ap-c-page__strong--FontWeight);
}
