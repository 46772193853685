/** ***************************************************************************
 * Molecules / Slider / Modal
 *************************************************************************** */

.ap-c-modal {
  --ap-c-modal--AccentColor: var(--ap-global--palette-green);
  --ap-c-modal--Color: var(--ap-global--palette-white);
  --ap-c-modal--Font: var(--ap-global--Font-dynamic-sm-regular);
  --ap-c-modal--LineHeight: 1.06;
  --ap-c-modal--Padding: 3.938rem 4.688rem;  /* 63px 75px */
  --ap-c-modal--Padding-mobile: 2rem;  /* 63px 75px */

  --ap-c-modal--ColumnGap: 0;
  --ap-c-modal--md-md--ColumnGap: 4.875rem;  /* 78px */

  --ap-c-modal--Grid:
    ".       heading close"   1fr
    ".       heading ."       auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;


  /**
   * Background
   */

  --ap-c-modal__background--BackgroundColor: var(--ap-global--palette-black);
  --ap-c-modal__background--TransitionDuration: var(--ap-global--Duration--md);


  /**
   * Mask
   */

  --ap-c-modal__mask--BackgroundColor: var(--ap-global--palette-gray);;
  --ap-c-modal__mask--TransitionDuration: calc(1.5 * var(--ap-c-modal__background--TransitionDuration));
  --ap-c-modal__mask--TransitionTimingFunction: var(--ap-global--AnimationTimingFunction--bounce);

  --ap-c-modal__mask--InlineSize: 200vmax;
  --ap-c-modal__mask--md-md--InlineSize: max(55.125rem, min(86.18vw, 94.375rem));  /*clamp(55.125rem, 86.18vw, 94.375rem)*/ /* 882px / 1510px */


  /**
   * Foreground
   */

  --ap-c-modal__foreground--TransitionDuration: 300ms;


  /**
   * Content
   */

  --ap-c-modal__content--MaxInlineSize: 34ch;
  --ap-c-modal__content--MarginBlockEnd: 2.5rem;  /* 39px */
  --ap-c-modal__content--LineHeight: 1.1em;


  /**
   * Close button
   */

  --ap-c-modal__close--JustifySelf: end;
  --ap-c-modal__close--md-md--JustifySelf: center;

  --ap-c-modal__close--AlignSelf: start;
  --ap-c-modal__close--md-md--AlignSelf: end;


  /**
   * Logo
   */

  --ap-c-modal__logo--BlockSize: 3.125rem;  /* 50px */
  --ap-c-modal__logo--Color: var(--ap-c-modal--AccentColor);
}

/** Inline: medium / Block: medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .ap-c-modal {
    --ap-c-modal--ColumnGap: var(--ap-c-modal--md-md--ColumnGap);
    --ap-c-modal__mask--InlineSize: var(--ap-c-modal__mask--md-md--InlineSize);
    --ap-c-modal__close--JustifySelf: var(--ap-c-modal__close--md-md--JustifySelf);
    --ap-c-modal__close--AlignSelf: var(--ap-c-modal__close--md-md--AlignSelf);
  }
}



/**
 * Component
 * 1: reset
 * 2: go fullscreen
 */

.ap-c-modal {
  accent-color: var(--ap-c-modal--AccentColor);
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  box-sizing: border-box;
  color: var(--ap-c-modal--Color);
  display: flex;
  gap: 4.688rem;
  font: var(--ap-c-modal--Font);
  /* grid-column-gap: var(--ap-c-modal--ColumnGap); */
  /* grid: var(--ap-c-modal--Grid); */
  min-height: 100%;  /* 2 */
  min-width: 100%;  /* 2 */
  padding: var(--ap-c-modal--Padding-mobile);
  position: fixed;
  text-align: center;
  visibility: hidden;
  flex-direction: column;
  justify-content: space-between;
  /* overflow: hidden; */
}

.ap-c-modal,
.ap-c-modal.ap-m-closing[open] {
  pointer-events: none;
  touch-events: none;
}

.ap-c-modal[open] {
  pointer-events: auto;
  touch-events: auto;
  visibility: visible;
}

@media (min-width: 67.5rem) {
  .ap-c-modal {
    padding: 3.938rem 4.688rem;
    flex-direction: row;
    justify-content: space-between;
  }
}



/**
 * Backdrop
 * 1: dialog-polyfill
 */

.ap-c-modal::backdrop {
  display: none;
}

.ap-c-modal + .backdrop {  /* 1 */
  display: none;
}



/**
 * Mask
 * 1: center in the viewport
 */

.ap-c-modal::after {
  aspect-ratio: 1 / 1;
  background-color: var(--ap-c-modal__mask--BackgroundColor);
  content: "";
  display: block;
  left: 50%;  /* 1 */
  position: fixed;
  top: 50%;  /* 1 */
  transform: translateX(-50%) translateY(-50%);
  transition: clip-path var(--ap-c-modal__mask--TransitionDuration) var(--ap-c-modal__mask--TransitionTimingFunction);
  width: 100vmax;
  z-index: 0;
}

.ap-c-modal::after,
.ap-c-modal.ap-m-closing[open]::after {
  clip-path: circle(0);
}

.ap-c-modal[open]::after {
  clip-path: circle(calc(var(--ap-c-modal__mask--InlineSize) / 2));
}

@supports not (aspect-ratio: 1 / 1) {
  .ap-c-modal::after {
    height: 100vmax;
  }
}



/**
 * Background
 */

.ap-c-modal::before {
  content: "";
  display: block;
  inset: 0;
  position: fixed;
  transition: background-color var(--ap-c-modal__background--TransitionDuration) linear;
}

.ap-c-modal::before,
.ap-c-modal.ap-m-closing[open]::before {
  background-color: transparent;
  transition-delay: calc(var(--ap-c-modal__mask--TransitionDuration) / 2);
}

.ap-c-modal[open]::before {
  background-color: var(--ap-c-modal__background--BackgroundColor);
  transition-delay: 0s;
}

@supports not (inset: 0) {
  .ap-c-modal::before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}



/**
 * Foreground
 */

.ap-c-modal > :is(h1, div, .ap-c-modal-button, svg, span, img, iframe) {
  transition: opacity var(--ap-c-modal__foreground--TransitionDuration) linear;
  z-index: 1;
}

.ap-c-modal > :is(h1, div, .ap-c-modal-button, svg,  span, img, iframe),
.ap-c-modal.ap-m-closing[open] > :is(h1, div, .ap-c-modal-button, svg, span, img, iframe) {
  opacity: 0;
  transition-delay: 0s;
}

.ap-c-modal[open] > :is(h1, div, .ap-c-modal-button, svg,  span, img, iframe) {
  opacity: 1;
  transition-delay: var(--ap-c-modal__mask--TransitionDuration);
}

.ap-c-modal img {
  object-fit: scale-down;
  max-height: 100%;
  /* opacity: 0; */
}

@media (min-width: 67.5rem) {
  .ap-c-modal img {
    width: 83%;
  }
}

.ap-c-modal #vimeo-video {
  display: none;
  flex: auto;
  position: relative;
  max-width: 100%;
}
.ap-c-modal #vimeo-video iframe,
.ap-c-modal #vimeo-video object,
.ap-c-modal #vimeo-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ap-c-modal .side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ap-c-modal .side .footer {
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;
  align-items: center;
}

@media (min-width: 67.5rem) {
  .ap-c-modal .side {
    flex-direction: column;
  }
  .ap-c-modal .side .footer {
    flex-direction: column;
  }
}

.ap-c-modal .ap-m-close {
  cursor: pointer;
}

.ap-c-modal .notation {
  font: var(--ap-global--Font-label--sm-medium);
  /* margin-top: 1.938rem; */
}

/**
 * Content
 * 1: reset
 */

.ap-c-modal > div {
  grid-area: content;
  justify-self: center;
  line-height: var(--ap-c-modal__content--LineHeight);
}

@media (min-width: 67.5rem) {
  .ap-c-modal > div {
    max-inline-size: var(--ap-c-modal__content--MaxInlineSize);
  }
}


.ap-c-modal > div > :is(:first-child, :last-child) {
  margin: 0;  /* 1 */
}

@supports not(max-inline-size: 0) {
  .ap-c-modal > div {
    max-width: var(--ap-c-modal__content--MaxInlineSize);
  }
}



/**
 * Close button
 */

.ap-c-modal .ap-c-modal-button.ap-m-close {
  align-self: var(--ap-c-modal__close--AlignSelf);
  grid-area: close;
  inset-block-start: 0;
  inset-inline-end: 0;
  justify-self: var(--ap-c-modal__close--JustifySelf);
  position: absolute;
}

@supports not (inset-block-start: 0) {
  .ap-c-modal .ap-c-modal-button.ap-m-close {
    right: 0;
    top: 0;
  }
}

@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .ap-c-modal .ap-c-modal-button.ap-m-close {
    position: static;
  }
}



/**
 * Logo
 */

.ap-c-modal .side > svg {
  block-size: var(--ap-c-modal__logo--BlockSize);
  color: var(--ap-c-modal__logo--Color);
  /* display: none; */
  inset-block-end: var(--ap-c-modal--Padding);
  inset-inline-end: var(--ap-c-modal--Padding);
  /* position: absolute; */
}

@supports not (inset-block-end: 0) {
  .ap-c-modal .side > svg {
    height: var(--ap-c-modal__logo--BlockSize);
    bottom: var(--ap-c-modal--Padding);
    right: var(--ap-c-modal--Padding);
  }
}

@media (min-width: 67.5rem) {
  .ap-c-modal .side > svg {
    --ap-c-modal__logo--BlockSize: 5rem;
    /* block-size: var(--ap-c-modal__logo--BlockSize) */
  }
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .ap-c-modal .side > svg {
    display: block;
  }
}

.ap-m-pause .ap-m-close {
  animation: none;
}
