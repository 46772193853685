/******************************************************************************
 * Molecules / Video
 *****************************************************************************/

.ap-c-video {
  display: grid;
  position: relative;
  height: calc(var(--space-xs-s) * 42);

  // @media all and (min-width: 67.5rem) {
  //   max-height: 46.75rem;
  //   min-height: 34.063rem;
  // }

  .current-section {
    display: inline-block;
    font: var(--ap-global--Font-label--sm-medium);
    color: var(--ap-global--palette-white);
    letter-spacing: 1px;
    position: absolute;
    bottom: 3.438em;  // 55px
    right: 2.125em;   // 34px
    text-transform: uppercase;
    transform-origin: bottom right;
    transform: rotate(-90deg) translateX(100%);

    /** Small */
    @media all and (min-width: 67.5rem) {
      bottom: unset;
      right: unset;
      top: 3.438em;
      left: 2.125em;
      transform-origin: left top;
      transform: rotate(-90deg) translateX(-100%);
    }
  }

  .video-overlay {
    background-color: var(--ap-global--palette-black--65);
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;

    @media all and (min-width: 67.5rem) {
      grid-column: 1/-1;
    }

    &:hover {
      .ap-c-button-circle.ap-m-play::after {
        padding: 8.25rem;

        @media all and (min-width: 67.5rem) {
          padding: calc(var(--fluid-12225-21733)* 1.1);
        }
      }
    }

    .play {
      font: var(--ap-global--Font-label--sm-medium);
      color: var(--ap-global--palette-white);
      letter-spacing: 1px;
      position: absolute;
      top: .875em;
      right: 1.188em;
      text-transform: uppercase;

      /** Small */
      @media all and (min-width: 67.5rem) {
        display: none;
      }
    }
  }

  .ap-c-button-circle.ap-m-play {
    --fluid-12225-21733: clamp(7.6406rem, 0.0003rem + 11.319vi, 13.5831rem);
    cursor: pointer;
    width: 7.5rem;  // 120px
    height: 7.5rem;

    > svg {
      inline-size: 1rem;
    }

    /** Small */
    @media all and (min-width: 67.5rem) {
      width: var(--fluid-12225-21733);
      height: var(--fluid-12225-21733);

      > svg {
        inline-size: calc(var(--space-xs-s) * 1.5);
      }
    }
  }

  .cta-description {
    display: flex;
    gap: 11.375rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content-video {
      display: none;
      --single-column-percentage: calc(100%/12);
      flex-basis: calc(6 * var(--single-column-percentage));
      color: var(--ap-global--palette-white);

      .title {
        font: var(--ap-global--Font-title--lg-regular);
        line-height: var(--step-7);
      }

      .description {
        font: var(--ap-global--Font-paragraph--sm-regular);
        line-height: var(--step-2);
        --single-column-percentage-desc: calc(100%/6);
        width: calc(5 * var(--single-column-percentage-desc));
      }

      /** Small */
      @media all and (min-width: 67.5rem) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 2.875rem;
        height: clamp(18.75rem, 5.8929rem + 19.0476vi, 28.75rem);
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media all and (min-width: 67.5rem) {
      grid-column: 1/-1;
    }
  }

  #video-player {
    display: none;
    max-width: 100%;
    height: 100vh;
    max-height: 34.063rem;

    /** Small */
    @media all and (min-width: 67.5rem) {
      max-height: 46.75rem;
      grid-column: 1/-1;
    }

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
