/******************************************************************************
 * Organisms / Intro Image
 *****************************************************************************/

section.ap-c-intro_image {
  display: grid;
  grid-template-columns: var(--ap-c-page--Grid-Template);
  grid-gap: 0 var(--ap-c-page--Grid-Gap);
  justify-content: center;
  justify-items: center;

  img {
    display: none;
    max-width: 100%;
    max-height: calc(var(--space-3xl) * 5.357);
    object-fit: cover;
    order: 3;
    grid-column: 3 / span 8;

    @media (min-width: 67.5rem) {
      display: initial;
      margin-top: clamp(-32.5rem, -26vw, -17.5rem);
    }
  }
}
