/** ***************************************************************************
 * Molecules / Slider / Slider
 *************************************************************************** */

@import '@splidejs/splide/dist/css/splide.min.css';

.splide {

  &__track {
    overflow: visible;
  }

  &__slide {
    img {
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      // width: 100%;
      // object-fit: scale-down;
      vertical-align: middle;
      animation: fadeIn ease 1s;
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    &[data-video] {
      .video-overlay {
        display: none;
        background-color: var(--ap-global--palette-black--60);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .play {
          font: var(--ap-global--Font-label--sm-medium);
          color: var(--ap-global--palette-white);
          letter-spacing: 1px;
          position: absolute;
          top: .875em;
          right: 1.188em;
          text-transform: uppercase;
        }

        &:hover {
          .ap-c-button-circle.ap-m-play::after {
            padding: calc(var(--space-l)* 1.05 * 2.7);
          }
        }
      }

      .ap-c-button-circle.ap-m-play {
        cursor: pointer;
      }
    }
  }

  &__arrows {
    display: none;
    position: relative;
    bottom: -2.625rem;

    .ap-m-detail & {
      top: 0.5rem;
      height: 2.5rem;
      bottom: unset;

      .splide__arrow {
        color: var(--ap-global--palette-white);

        &:disabled {
          color: var(--ap-global--palette-white--30);
        }
      }
    }

    .splide__arrow {
      background-color: transparent;
      top: unset;
      opacity: 1;
      font-size: 1rem;
      color: var(--ap-global--palette-black);

      svg {
        width: 1em;
        height: 1em;
      }

      &--prev {
        left: 0;
      }

      &--next {
        left: 2.875em;
      }

      &:disabled {
        color: var(--ap-global--palette-black--27);
      }

      &:focus-visible {
        outline: var(--ap-global--Outline--black);
      }
    }

    @media (min-width: 67.5rem) {
      display: block;
    }
  }

  .notation {
    font: var(--ap-global--Font-label--xs-regular);
    color: var(--ap-global--palette-white);
    margin-top: 1.688em;
    margin-left: .5em;
  }

  &.is-focus-in .splide__arrow:focus {
    outline: var(--ap-global--Outline--black);
  }

  .splide__slide img {
    cursor: pointer;
  }
}
