/******************************************************************************
 * Organisms / Footer
 *****************************************************************************/

.ap-c-footer {
  color: var(--ap-global--palette-white);
  background-color: var(--ap-global--palette-black);
  position: relative;
  --separator-color: rgba(250, 249, 247, 0.25);

  .container {
    max-width: var(--ap-global--breakpoints--block-xl);
    margin: 0 auto;
    padding: 3.813rem 1.875rem; // 61px 30px
    position: relative;

    @media all and (min-width: 67.5rem) {
      --fluid-177-31467: clamp(11.0625rem, -0.0003rem + 16.3893vi, 19.6669rem);
      height: var(--fluid-177-31467);
      padding: 4.875rem 2.500rem; // 78px 40px
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      @media all and (min-width: 67.5rem) {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .ap-title {
    padding-bottom: 2.333rem; // 37.33px;

    span {
      font-size: 2.4rem;
      line-height: 2.2rem;
    }

    @media all and (min-width: 67.5rem) {
      padding-bottom: unset;
    }
  }

  .personal-infos {
    font: var(--ap-global--Font-label--ml-light);
    display: flex;
    flex-direction: column;

    .contacts {
      padding-block: 1.438rem; // 23px;
      border-top: 1px solid var(--separator-color);
    }

    strong {
      text-transform: capitalize;
      margin-right: 0.3rem;
    }

    .slash {
      letter-spacing: 0.15rem;
    }

    .value {
      margin-left: 0.1rem;
    }

    @media all and (min-width: 67.5rem) {
      flex-direction: row;

      .contacts {
        border-left: 1px solid var(--separator-color);
        border-top: unset;
        padding-inline: 2.625rem 3.938rem; // 42px 63px;
      }

      .socials {
        border-left: 1px solid var(--separator-color);
        padding-inline: 2.625rem 0;
        padding-block: 1.438rem; // 23px;
      }
    }

    // eccezione di mobile-first solo per comodità nella gestione della tipografia
    @media all and (max-width: 67.5rem) {
      font-size: 1.125rem;
    }
  }

  .credits {
    --fluid-31-39: clamp(1.9375rem, 1.8161rem + 0.5178vi, 2.4375rem);
    --fluid-20-3367: clamp(1.25rem, 1.0426rem + 0.8848vi, 2.1044rem);
    position: absolute;
    right: var(--fluid-31-39);
    bottom: var(--fluid-20-3367);
    // font: var(--ap-global--Font-label--md-medium);
    font-family: 'EuclidCircularB';
    font-size: 1.125rem;
    display: flex;
    gap: .375rem;
    align-items: center;

    span {
      --fluid-1425-2533: clamp(0.8906rem, 0.0003rem + 1.319vi, 1.5831rem);
      width: 1.188rem;
      height: 1.188rem;
      animation: spin 3s linear infinite;

      svg {
        width: 1.188rem;
        height: 1.188rem;

        @media all and (min-width: 67.5rem) {
          width: var(--fluid-1425-2533);
          height: var(--fluid-1425-2533);
        }
      }

      @media all and (min-width: 67.5rem) {
        width: var(--fluid-1425-2533);
        height: var(--fluid-1425-2533);
      }
    }


    @media all and (min-width: 67.5rem) {
      font-size: var(--step-0);
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
