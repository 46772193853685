/******************************************************************************
 * Atoms / Button / Base
 *****************************************************************************/

.ap-c-button {
  --ap-c-button--BackgroundColor: var(--ap-global--palette-white);
  --ap-c-button--BorderRadius: 100vmax;  /* pill */
  --ap-c-button--Color: var(--ap-global--palette-black);
  --ap-c-button--Font: var(--ap-global--Font-label--md-regular);
  --ap-c-button--PaddingBlock: .5em;  /* 9px */
  --ap-c-button--PaddingInline: 1.667em;  /* 30px */
  --ap-c-button--Border: var(--ap-global--Border--black);

  --ap-c-button--disabled--BackgroundColor: var(--ap-global--palette-gray-dark);
  --ap-c-button--disabled--Color: var(--ap-global--palette-white);
}



/**
 * Component
 * 1: solve a priority problem
 */

/* a.ap-c-button,  /* 1 */
/* button.ap-c-button {  /* 1 */
.ap-c-button {
  background-color: var(--ap-c-button--BackgroundColor);
  border-radius: var(--ap-c-button--BorderRadius);
  border: var(--ap-c-button--Border);
  box-sizing: border-box;
  color: var(--ap-c-button--Color);
  display: inline-block;
  font: var(--ap-c-button--Font);
  justify-self: start;
  padding: var(--ap-c-button--PaddingBlock) var(--ap-c-button--PaddingInline);
  text-align: center;
  transition:
    background-color .1s linear,
    color .1s linear;
}

/*
.ap-c-button:hover {
  background-color: var(--ap-c-button--hover--BackgroundColor, var(--ap-c-button--Color));
  border-color: var(--ap-c-button--hover--BorderColor, var(--ap-c-button--hover--BackgroundColor, var(--ap-c-button--Color)));
  color: var(--ap-c-button--hover--Color, var(--ap-c-button--BackgroundColor));
}

.ap-c-button:disabled {
  background-color: var(--ap-c-button--disabled--BackgroundColor, var(--ap-c-button--Color));
  border-color: var(--ap-c-button--disabled--BorderColor, var(--ap-c-button--hover--BackgroundColor, var(--ap-c-button--Color)));
  color: var(--ap-c-button--disabled--Color, var(--ap-c-button--BackgroundColor));
  cursor: default;
}

.ap-c-button:active {
  background-color: var(--ap-c-button--active--BackgroundColor, var(--ap-c-button--hover--BackgroundColor, var(--ap-c-button--Color)));
  border-color: var(--ap-c-button--active--BorderColor, var(--ap-c-button--hover--BorderColor, var(--ap-c-button--hover--BackgroundColor, var(--ap-c-button--Color))));
  color: var(--ap-c-button--active--Color, var(--ap-c-button--hover--Color, var(--ap-c-button--BackgroundColor)));
} */
