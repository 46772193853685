/******************************************************************************
 * Atoms / Logo
 *****************************************************************************/
@import '~/src/styles/main/mixins.scss';

.ap-title {
  text-transform: uppercase;
  font-size: 0;

  span {
    // font: var(--ap-global--Font-paragraph--lg-regular);
    @include logo-font;

    &[class^="hide"] {
      letter-spacing: -1em;
      opacity: 0;
    }

    &.reveal {
      opacity: 1;
    }
  }

  &.ap-title--full {

    span {
      &.reveal {
        opacity: 0;
      }

      &[class^="hide"] {
        letter-spacing: 0;
        opacity: 1;
      }
    }

  }
}

