.custom-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: clamp(10px, 2vw, 15px);
  margin: 0 auto;
  padding: 20px;
  justify-content: center;
}

@media (min-width: 1081px) {
  .custom-container {
    grid-template-columns: repeat(12, minmax(71px, 127px));
    grid-gap: clamp(15px, 1.5vw, 26px);
    max-width: 1920px;
  }
}

.custom-column {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Elemento che occupa 4 colonne in visualizzazione estesa e 2 in visualizzazione compatta */
.wide-element {
  grid-column: span 4;
}

@media (max-width: 1080px) {
  .wide-element {
    grid-column: span 2;
  }
}
