/******************************************************************************
 * Atoms / Button / Circle / Play
 *****************************************************************************/

.ap-c-button-circle.ap-m-play {
  position: relative;
  border: none;
}

.ap-c-button-circle.ap-m-play::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--ap-global--palette-white);
  opacity: .4;
  transition: all .5s ease;
  z-index: -1;
}
.ap-c-button-circle.ap-m-play:hover::after {
  /* width: 264px;
  height: 264px; */
  padding: calc(var(--space-l)* 1.05 * 2.7);
}
